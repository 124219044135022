import { Controller } from "stimulus";

// Use this Stimulus controller to toggle an element using 'toggleElement' based on the
// checked value of 'checkbox'. Controller has optional feature:
//   1. You can show and hide the element using the 'toggleVisualElement'
export default class extends Controller {
  static targets = ['checkbox', 'toggleElement', 'toggleVisualElement']

  connect() {
    if (this.checkboxTarget.checked) {
      this.toggle()
    }
  }

  toggle() {
    this.toggleElementTargets.forEach((toggleElementTarget) => {
      toggleElementTarget.disabled = !toggleElementTarget.disabled

      // In the legacy JS components the TomSelect instances listen to this event
      // to toggle the disabled state of the input, since the original select input
      // is substituted
      toggleElementTarget.dispatchEvent(new Event("toggleDisable"))
    })

    if (this.hasToggleVisualElementTarget) {
      this.toggleVisualElementTargets.forEach((toggleVisualElementTarget) => {
        toggleVisualElementTarget.classList.toggle('hidden')
      })
    }
  }
}
